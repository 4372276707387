import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { getApiUrl } from '../../helper/urlsHelper';
import { siteConst } from '../../constants';

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ productText, productId } , { rejectWithValue }) => {
    try {
      const apiUrl = productText ? API.PRODUCT_SEARCH_BY_NAME : productId ? API.PRODUCT_SEARCH_BY_ID : API.PRODUCT_SEARCH;
      const apiParam = productText ? productText : productId ? productId : '';
      const response = await axios.get( getApiUrl(`${apiUrl}${apiParam}`) );
      return response.data;
    } catch (error) {
      const { response } = error;
      const resObj = {message: null, error: false};
      if (response && response.data) {
        const { message, exception, errors } = response.data; 
        resObj.message = message ? message : exception ? siteConst.MSG_SERVICE_EXCEPTION : siteConst.MSG_SERVICE_ERROR;
        resObj.error = errors;
        return rejectWithValue(resObj);
      }
    }
  }
);


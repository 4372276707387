import React from 'react';
import _ from 'lodash';

function ProductList (props) {
  const { products, isControlsActive, selectProductCb } = props;

  const renderDetails = ({ id, name, slug, description, price }) => (
    <li className='list-group-item shadow-sm p-1 mb-1 bg-body rounded' key={`${id}-${name}`}>
      <div className='card border-0 bg-transparent mb-3'>
        <div className='card-body text-secondary'>
          <h5 className='card-title text-info'>
            {name}
          </h5>
          <p className='card-text sub-details'>
            <span>Code: <em>{slug}</em></span>
            <span>Price: <em className="text-danger">${price}</em></span>
          </p>
          <p className='card-text'>
            {description}
          </p>
          { isControlsActive && 
            <p className='card-text text-end'>
              <div className="btn-group btn-group-sm">
                <button type="button" className="btn btn-primary" onClick={() => selectProductCb('edit', id)}>Edit</button>
                <button type="button" className="btn btn-secondary" onClick={() => selectProductCb('delete', id)}>Delete</button>
              </div>
            </p>
          }
        </div>
      </div>
    </li>
  );

  const listing = () => (
    <ul className='list-group list-group-flush'>
      { _.isArray(products) && products.map((product) => renderDetails(product))}
      { !_.isArray(products) && renderDetails(products)}
    </ul>
  );

  const noProducts = () => (
    <div className="alert alert-Warning" role="alert">
      No products found!
    </div>
  );
  return products ? listing() : noProducts();
}

export default ProductList;
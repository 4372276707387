import React from 'react';

export default function AjaxLoader (props)  {
    const { isLoading } = props;

    return isLoading && (
        <>
            <div className="modal show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content text-center">
                        <div className="modal-body">
                            <span className="spinner-grow spinner-grow-sm mx-2" role="status"></span>
                            <span className="spinner-grow spinner-grow-sm mx-2" role="status"></span>
                            <span className="spinner-grow spinner-grow-sm mx-2" role="status"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
}
import React, { useState } from 'react';

function ProductEdit (props) {
  const { id, updateCbFunc } = props;
  const [ name, setName ] = useState(props.name);
  const [ slug, setSlug ] = useState(props.slug);
  const [ description, setDescription ] = useState(props.description);
  const [ price, setPrice ] = useState(props.price);

  const submitForm = (e) => {
    e.preventDefault();
    if (updateCbFunc) {
      updateCbFunc({ id, name, slug, description, price });
    }
  };

  const cancelForm = () => {
    if (updateCbFunc) {
      updateCbFunc();
    }
  };

  const renderEditPanel = () => (
    <form noValidate onSubmit={submitForm}>
      <div className='row'>
        <div className='mb-3 col-12 col-md-4'>
          <label htmlFor='product-name' className='mb-0 text-end'>Product Name</label>
        </div>
        <div className='col-12 col-md-8'>
          <input
            type="text"
            placeholder="product name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            id='product-name'
            name='name'
            className={`form-control`}
          />
        </div>
      </div>
      <div className='row'>
        <div className='mb-3 col-12 col-md-4'>
          <label htmlFor='product-slug' className='mb-0 text-end'>Product Slug</label>
        </div>
        <div className='col-12 col-md-8'>
          <input
            type="text"
            placeholder="product slug"
            onChange={(e) => setSlug(e.target.value)}
            value={slug}
            id='product-slug'
            name='slug'
            className={`form-control`}
          />
        </div>
      </div>
      <div className='row'>
        <div className='mb-3 col-12 col-md-4'>
          <label htmlFor='product-price' className='mb-0 text-end'>Product Price</label>
        </div>
        <div className='col-12 col-md-8'>
          <input
            type="text"
            placeholder="product price"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            id='product-price'
            name='price'
            className={`form-control`}
          />
        </div>
      </div>
      <div className='row'>
        <div className='mb-3 col-12 col-md-4'>
          <label htmlFor='product-desc' className='mb-0'>Product Description</label>
        </div>
        <div className='col-12 col-md-8'>
          <textarea
            placeholder="product description"
            onChange={(e) => setDescription(e.target.value)}
            id='product-desc'
            name='description'
            className={`form-control`}
            rows='7'
            style={{ resize: 'none' }}
            value={description}
          />
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-12 text-end'>
          <div className="btn-group btn-group-sm">
            <button
              className='btn btn-outline-secondary' type='button' onClick={cancelForm}
            >
              Cancel
            </button>
            <button
              className='btn btn-outline-primary' type='button' onClick={submitForm}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
    return renderEditPanel();
}

export default ProductEdit;
import React, { useState, useEffect } from 'react';
import _, { isNull } from 'lodash';
import { useSelector, useDispatch }  from 'react-redux';
import PageTitle from '../components/PageTitle';
import ProductList from '../components/ProductList';
import ProductSearch from '../components/ProductSearch';
import ProductEdit from '../components/ProductEdit';
import AjaxLoader from '../components/AjaxLoader';
import { fetchProducts } from '../features/products/productsAPI';
import { getProducts, getIsLoading } from '../features/products/productsSlice';
import { getIsUserLoggedIn } from '../features/users/userSlice';

function Products() {
  const [ errors, setErrors ] = useState(false);
  const [ activeProductId, setActiveProductId ] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const productsList = useSelector(getProducts);
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  useEffect( () => {
    dispatch(fetchProducts({ productText: false, productId: false }));
  },[]);

  const getActiveProductDetails = () => {
    const activeProduct = productsList.filter(pl => pl.id === activeProductId);
    return activeProduct ? {...activeProduct[0]} : false;
  }

  const updateProductList = (value) => {
    const obj = { productText: false, productId: false };
    if ( ! isNaN(value) ) {
      obj.productId = value;
    } else if ( !isNull(value) && isNaN(value) ) {
      obj.productText = value;
    }
    dispatch(fetchProducts(obj));
  }

  const handleSelectProductCb = (type, id) => {
    setActiveProductId(id);
  }

  const handleProductEditCb = (values) => {
    if (values) {
      console.log(values);
    }
    setActiveProductId(false);
  }

	return (
    <div className='container page-products'>
			<div className='row'>
				<div className='col'>
					<PageTitle title='Product Page' />
				</div>
			</div>
			<div className='row justify-content-md-center'>
        { !_.isEmpty(errors) && 
          <div className='col-12 text-center'>
            <span className='text-danger'>
              {errors.statusMessage || 'Please check the errors!'}
            </span>
          </div>
        }
        { !activeProductId && 
          <>
            <div className='col-12'>
              <ProductSearch updateProductListFunc={updateProductList} />
            </div>
            <div className='col-12 mt-4'>
              <ProductList products={productsList} isControlsActive={isUserLoggedIn} selectProductCb={handleSelectProductCb} />
            </div>
          </>
        }
        { activeProductId && isUserLoggedIn &&
          <>
            <div className='col-12 mt-4'>
              <ProductEdit {...getActiveProductDetails()} updateCbFunc={handleProductEditCb}/>
            </div>
          </>
        }
			</div>
      <AjaxLoader isLoading={isLoading} />
		</div>
	);
}

export default Products;
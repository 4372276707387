import { API_URL, SITE_KEY } from "../config";
const CryptoJS = require('crypto-js');

export function getApiUrl(apiPath, apiURL = API_URL) {
  return `${API_URL}${apiPath}`;
}

export function tempData() {
  function save(key, value) {
    if (key && value) {
      const finalValue = CryptoJS.AES.encrypt(JSON.stringify(value), SITE_KEY).toString();
      sessionStorage.setItem(key, finalValue);
    }
  }
  function get(key) {
    let returnValue = false;
    if (key) {
      var value = sessionStorage.getItem(key);
      if (value) {
        var bytes = CryptoJS.AES.decrypt(value, SITE_KEY);
        returnValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
    return returnValue;
  }
  function remove(key) {
    if (key) {
      sessionStorage.removeItem(key);
    }
  }

  return { save, get, remove };
}

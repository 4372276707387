import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import BreadCrumb from './components/BreadCrumb';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import "bootstrap/dist/css/bootstrap.min.css";

import { useSelector, useDispatch } from 'react-redux';
import { getIsUserLoggedIn } from '../src/features/users/userSlice';
import { processLogout } from './features/users/userAPI';

function App() {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const handleLogoutClick = () => {
    dispatch(processLogout({}));
  }

  return (
    <BrowserRouter>
      <BreadCrumb isUserLoggedIn={isUserLoggedIn} logoutFn={handleLogoutClick}/>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/products" element={<Products />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch }  from 'react-redux';
import PageTitle from '../components/PageTitle';
import AjaxLoader from '../components/AjaxLoader';
import { processLogin } from '../features/users/userAPI';
import { getIsLoading, getIsUserLoggedIn, getHasError, getErrorDetails, getMessage } from '../features/users/userSlice';

function Login() {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ errors, setErrors ] = useState({});
    const initialLoad = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isUserLoggedIn = useSelector(getIsUserLoggedIn);
    const isLoading = useSelector(getIsLoading);
    const errorDetails = useSelector(getErrorDetails);
    const errorStatus = useSelector(getHasError);
    const message = useSelector(getMessage);

    useEffect(() => {
        initialLoad.current = false;
    },[]);
    
    useEffect(() => {
        if (errorDetails || errorStatus) {
            setErrors({ ...errorDetails, ...errorStatus });
        }
    },[errorDetails, errorStatus]);

    useEffect(() => {
        if (isUserLoggedIn) {
            navigate('/dashboard');
        }
    }, [isUserLoggedIn, navigate]);

    const updateValue = (event) => {
        const target = event.target;
        if (target.name === 'email') {
            setEmail(target.value);
        } else if (target.name=== 'password') {
            setPassword(target.value);
        }
        const tempErrors = {...errors};
        if ( _.isEmpty(target.value) ) {
            tempErrors[target.name] = 'Invalid data!';
        } else {
            delete tempErrors[target.name];
        }
        setErrors({...tempErrors});
    };

    const validateError = () => {
        const errors = {};
        if (email === '') {
            errors['email'] = 'Invalid data!';
        }
        if (password === '') {
            errors['password'] = 'Invalid data!';
        }

        if (!_.isEmpty(errors)) {
            setErrors(errors);
            return false;
        }
        setErrors([]);
        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateError()) {
            dispatch(processLogin({ email, password }));
        }
    };

	const renderLoginScreen = () => (
    <form noValidate onSubmit={handleSubmit}>
      <div className='mb-3'>
        <label htmlFor='login-email'>Email address</label>
        <input type='email' value={email} name="email" id='login-email' placeholder='Enter email'
            className={`form-control ${ errors['email'] ? 'is-invalid' : ''}`} onChange={updateValue} />
        <div className='invalid-feedback'>{errors['email']}</div>
      </div>
      <div className='mb-3'>
        <label htmlFor='login-pass'>Password</label>
        <input
            type='password' value={password} name="password" id='login-pass' placeholder='Password'
            className={`form-control ${ errors['password'] ? 'is-invalid' : ''}`} onChange={updateValue} />
        <div className='invalid-feedback'>{errors['password']}</div>
      </div>
      
      <div className='mb-3 text-end'>
        <Link to='/register' className='d-inline-block me-2'>Register yourself</Link>
        <button type='submit' className='btn btn-primary'>
            Submit
        </button>
      </div>
    </form>
	);

	return (
		<div className='container page-login'>
			<div className='row'>
				<div className='col'>
					<PageTitle title='Login Page' />
				</div>
			</div>
			<div className='row justify-content-md-center'>
        { !_.isEmpty(message) && 
            <div className='col-12 text-center'>
                <span className='text-info'>
                {message}
                </span>
            </div>
        }
        { !_.isEmpty(errors) && 
            <div className='col-12 text-center'>
                <span className='text-danger'>
                {errors.statusMessage || 'Please check the errors!'}
                </span>
            </div>
        }
				<div className='col col-md-5'>
					{renderLoginScreen()}
				</div>
			</div>
        <AjaxLoader isLoading={isLoading} />
		</div>
	);
}

export default Login;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageTitle from '../components/PageTitle';
import { getIsUserLoggedIn } from '../features/users/userSlice';

function Dashboard() {
    const isUserLoggedIn = useSelector(getIsUserLoggedIn);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isUserLoggedIn) {
            navigate('/login');
        }
    }, [isUserLoggedIn]);

	return (
        <div className='container page-login'>
			<div className='row'>
				<div className='col'>
					<PageTitle title='Dashboard Page' />
				</div>
			</div>
			<div className='row justify-content-md-center'>
                <div className='col col-md-5'>
					........Dasboard page Content........
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
const API = {
    PRODUCT_SEARCH: 'api/products',
    PRODUCT_SEARCH_BY_ID: 'api/products/',
    PRODUCT_SEARCH_BY_NAME: 'api/products/search/',
    REGISTER: 'api/register',
    LOGIN: 'api/login',
    LOGOUT: 'api/logout'
};

const API_URL = process.env.REACT_APP_MAIN_APT_HOST || 'http://la-dev.b2where.local/';

const SITE_KEY = 'b2soft';

export { API, API_URL, SITE_KEY} ;
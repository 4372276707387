import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import PageTitle from '../components/PageTitle';
import AjaxLoader from '../components/AjaxLoader';
import { processRegister } from '../features/users/userAPI';
import { getIsLoading, getHasError, getErrorDetails, getIsRegistered } from '../features/users/userSlice';
import { siteConst } from './../constants';

function Register() {
    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);
    const errorDetails = useSelector(getErrorDetails);
    const errorStatus = useSelector(getHasError);
    const isResigtered = useSelector(getIsRegistered);
    const [ message, setMessage ] = useState('');
    const [ errors, setErrors ] = useState({});
    const [ fields, setFields ] = useState({ regName: '', email: '', password: '', confirmPassword: '' });

    useEffect(() => {
        if (errorDetails || errorStatus) {
            setErrors({ ...errorDetails, ...errorStatus });
        }
    },[errorDetails, errorStatus]);
    
    useEffect(() => {
        if (isResigtered) {
            setMessage(siteConst.MSG_REGISTRATION_SUCCESS);
            setFields({ regName: '', email: '', password: '', confirmPassword: '' });
        }
    }, [isResigtered]);

    const updateValue = (event) => {
        const target = event.target;
        setFields({...fields, [target.name]: target.value});
        const tempErrors = {};
        if ( _.isEmpty(target.value) ) {
            tempErrors[target.name] = 'Data required!';
        } else {
            delete tempErrors[target.name];
        }
        setErrors({...tempErrors});
        setMessage('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const tempErrors = {};
        _.forEach(['regName', 'email', 'password', 'confirmPassword'], (itm) => {
            if ( _.isEmpty(fields[itm]) ) {
                tempErrors[itm] = 'Data required!';
            } else {
                delete tempErrors[itm];
            }
        });
        if (fields['password'] !== fields['confirmPassword']) {
            tempErrors.confirmPassword = 'Passwords are not same!'
        }
        setErrors({...tempErrors});
        setMessage('');

        if (_.isEmpty(tempErrors)) {
            const { regName, email, password, confirmPassword } = fields;
            dispatch(processRegister({ regName, email, password, confirmPassword }));
        }
    }

	const renderRegisterScreen = () => {
        const { regName, email, password, confirmPassword } = fields;
        return (
            <form noValidate onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <label htmlFor='reg-name'>Name</label>
                    <input
                        type='text' value={regName} name="regName" id='reg-name' placeholder='Enter name'
                        className={`form-control ${ errors['regName'] ? 'is-invalid' : ''}`}
                        onChange={updateValue}
                    />
                    <div className='invalid-feedback'>{errors['regName']}</div>
                </div>
                <div className='mb-3'>
                    <label htmlFor='reg-email'>Email Address</label>
                    <input
                        type='email' value={email} name="email" id='reg-email' placeholder='Enter email'
                        className={`form-control ${ errors['email'] ? 'is-invalid' : ''}`} onChange={updateValue}
                    />
                    <div className='invalid-feedback'>{errors['email']}</div>
                </div>
                <div className='mb-3'>
                    <label htmlFor='reg-password'>Password</label>
                    <input
                        type='password' value={password} name="password" id='reg-password' placeholder='Password'
                        className={`form-control ${ errors['password'] ? 'is-invalid' : ''}`} onChange={updateValue}
                    />
                    <div className='invalid-feedback'>{errors['password']}</div>
                </div>
                <div className='mb-3'>
                    <label htmlFor='reg-confirm-password'>Confirm Password</label>
                    <input
                        type='password' value={confirmPassword} name="confirmPassword" id='reg-confirm-password' placeholder='Confirm Password' className={`form-control ${ errors['confirmPassword'] ? 'is-invalid' : ''}`} onChange={updateValue}
                    />
                    <div className='invalid-feedback'>{errors['confirmPassword']}</div>
                </div>
                <div className='mb-3 text-end'>
                    <Link to='/login' className='d-inline-block me-2'>Login</Link>
                    <button type='submit' className='btn btn-primary'>
                        Submit
                    </button>
                </div>
            </form>
        );
    }

	return (
    <div className='container page-login'>
			<div className='row'>
				<div className='col'>
					<PageTitle title='Register Page' />
				</div>
			</div>
			<div className='row justify-content-md-center'>
        { !_.isEmpty(errors) && 
          <div className='col-12 text-center'>
            <span className='text-danger'>
              {errors.statusMessage || 'Please check the errors!'}
            </span>
          </div>
        }
        { !_.isEmpty(message) &&
          <div className='col-12 text-center'>
            <span className='text-success'>
              {message}
            </span>
          </div>
        }
				<div className='col col-md-5'>
					{renderRegisterScreen()}
				</div>
			</div>
      <AjaxLoader isLoading={isLoading} />
		</div>
	);
}

export default Register;
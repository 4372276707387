import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { getApiUrl, tempData } from '../../helper/urlsHelper';
import { siteConst } from '../../constants';

export const processRegister = createAsyncThunk(
    'users/processRegister',
    async ({ regName, email, password, confirmPassword } , { rejectWithValue }) => {
        try {
            const response = await axios.post(
                getApiUrl(API.REGISTER), {
                    name: regName,
                    email,
                    password,
                    password_confirmation: confirmPassword
                }
            );
            if (response.data) {
              const { token } = response.data;
              const tData = tempData();
              tData.save('uid', token);
            }
            return response.data;
        } catch (error) {
            const { response } = error;
            const resObj = {message: null, error: false};
            if (response && response.data) {
                const { message, exception, errors } = response.data; 
                resObj.message = message ? message : exception ? siteConst.MSG_SERVICE_EXCEPTION : siteConst.MSG_SERVICE_ERROR;
                Object.keys(errors).forEach( key => {
                    if (key === 'name') {
                        errors['regName'] = errors[key];
                    }
                });
                resObj.error = errors;
            } else {
                resObj.message = siteConst.MSG_NETWORK_ERROR;
            }
            return rejectWithValue(resObj);
        }
    }
);

export const processLogin = createAsyncThunk(
    'users/processLogin',
    async ({ email, password }, { rejectWithValue }) => {
        try {
          const response = await axios.post(
              getApiUrl(API.LOGIN), { email, password }
          );
          if (response.data) {
            const { token } = response.data;
            const tData = tempData();
            tData.save('uid', token);
          }
          return response.data;
        } catch (error) {
          const { response } = error;
          const resObj = {message: null, error: false};
          if (response && response.data) {
              const { message, exception, errors } = response.data; 
              resObj.message = message ? message : exception ? siteConst.MSG_SERVICE_EXCEPTION : siteConst.MSG_SERVICE_ERROR;
              resObj.error = errors;
          } else {
              resObj.message = siteConst.MSG_NETWORK_ERROR;
          }
          return rejectWithValue(resObj);
        }
    }
);

export const processLogout = createAsyncThunk(
  'users/processLogout',
  async ({}, { rejectWithValue }) => {
    try {
      const tData = tempData();
      const token = tData.get('uid').substring(3);
      const response = await axios.get(
        getApiUrl(API.LOGOUT),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      if (response.data) {
        const tData = tempData();
        tData.remove('uid');
      }
      return response.data;
    } catch (error) {
      console.log('not ok', error);
      const { response } = error;
      const resObj = {message: null, error: false};
      if (response && response.data) {
        const { message, exception, errors } = response.data; 
        resObj.message = message ? message : exception ? siteConst.MSG_SERVICE_EXCEPTION : siteConst.MSG_SERVICE_ERROR;
        resObj.error = errors;
      } else {
        resObj.message = siteConst.MSG_NETWORK_ERROR;
      }
      return rejectWithValue(resObj);
    }
  }
);


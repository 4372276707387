import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function BreadCrumb ({ isUserLoggedIn, logoutFn }) {
    const location = useLocation();
    const { pathname } = location;
    const currentPath = pathname.split("/")[1];
    const handleLogoutClick = (e) => {
      if (logoutFn) {
        logoutFn();
      }
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-demoapp" aria-controls="navbar-demoapp" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbar-demoapp">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        { !isUserLoggedIn && (
                            <>
                                <li className="nav-item">
                                    <Link to="/login" className={`nav-link ${(currentPath === 'login') ? 'active' : ''}`}>
                                        Login
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/register" className={`nav-link ${(currentPath === 'register') ? 'active' : ''}`}>
                                        Register
                                    </Link>
                                </li>
                            </>
                        )}
                        { isUserLoggedIn && (
                            <li className="nav-item">
                                <Link to="/dashboard" className={`nav-link ${(currentPath === 'dashboard') ? 'active' : ''}`}>
                                    Dashboard
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <Link to="/products" className={`nav-link ${(currentPath === 'products') ? 'active' : ''}`}>
                                Products
                            </Link>
                        </li>
                        { isUserLoggedIn && (
                          <li className="nav-item">
                            <button className='btn btn-link nav-link' onClick={handleLogoutClick}>
                              Logout
                            </button>
                          </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default BreadCrumb;
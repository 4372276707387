import { createSlice } from "@reduxjs/toolkit";
import { fetchProducts } from './productsAPI';

const initialState = {
  productList: [],
  isLoading: false,
  hasError: false,
  errorDetails: false
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchProducts.pending, (state, action) => {
          state.isLoading = true;
          state.hasError = false;
          state.errorDetails = false;
        })
        .addCase(fetchProducts.fulfilled, (state, action) => {
          state.isLoading = false;
          state.productList = action.payload;
        })
        .addCase(fetchProducts.rejected, (state, action) => {
          state.isLoading = false;
          state.hasError = action.payload.message;
          state.errorDetails = action.payload.error;
        })
    }
});

export const getProducts = (state) => state.products.productList;
export const getIsLoading = (state) => state.products.isLoading;
export const getHasError = (state) => {
    return state.products.hasError 
        ? { statusMessage: state.products.hasError } : false;
}
export const getErrorDetails = (state) => state.products.errorDetails;

const productsReducer = productsSlice.reducer;
export default productsReducer;
import { createSlice } from "@reduxjs/toolkit";
import { processRegister, processLogin, processLogout } from './userAPI';

const initialState = {
  isUserLoggedIn: false,
  isLoading: false,
  hasError: false,
  errorDetails: false,
  isResigtered: false,
  message: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(processRegister.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
                state.errorDetails = false;
                state.isResigtered = false;
                state.message = false;
            })
            .addCase(processRegister.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isResigtered = true;
            })
            .addCase(processRegister.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = action.payload.message;
                state.errorDetails = action.payload.error;
            })
            .addCase(processLogin.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
                state.errorDetails = false;
                state.isResigtered = false;
                state.message = false;
            })
            .addCase(processLogin.fulfilled, (state, action) => {
              const { user, token } = action.payload;
              state.isLoading = false;
              if ( token && user ) {
                state.isUserLoggedIn = true;
              }
            })
            .addCase(processLogin.rejected, (state, action) => {
              state.isLoading = false;
              state.hasError = action.payload.message;
              state.errorDetails = action.payload.error;
            })
            .addCase(processLogout.pending, (state, action) => {
              state.isLoading = true;
              state.hasError = false;
              state.errorDetails = false;
              state.isResigtered = false;
              state.message = false;
            })
            .addCase(processLogout.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isUserLoggedIn = false;
                state.token = false;
                state.message = action.payload.message;
            })
            .addCase(processLogout.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = action.payload.message;
                state.errorDetails = action.payload.error;
            })
    }
});

export const getToken = (state) => state.user.isTokenAvailable;
export const getIsLoading = (state) => state.user.isLoading;
export const getIsUserLoggedIn = (state) => state.user.isUserLoggedIn;
export const getHasError = (state) => {
    return state.user.hasError 
        ? { statusMessage: state.user.hasError } : false;
}
export const getErrorDetails = (state) => state.user.errorDetails;
export const getIsRegistered = (state) => state.user.isResigtered;
export const getMessage = (state) => state.user.message;

const userReducer = userSlice.reducer;
export default userReducer;
import axios from 'axios';
import React, { useState } from 'react';

function ProductSearch (props) {
    const [ searchString, setSearchString ] = useState('');
    const { updateProductListFunc } = props;

    const handleSearchString = (event) => {
        setSearchString(event.currentTarget.value.trim());
    }
    const handleClearSearch = () => {
        setSearchString('');
        execCallback(false);
    }
    const submitSearch = () => {
        if (searchString === '') return;
        execCallback(searchString);
    }
    const execCallback = (value) => {
      if (updateProductListFunc) {
        updateProductListFunc(value);
      }
    }

    const renderSearchPanel = () => (
      <form noValidate className='row'>
        <div className='mb-3 col-12'>
          <label htmlFor='product-search' className='h5 mb-0'>Search Product</label>
        </div>
        <div className='col-12 col-md-6'>
          <input
            type="text"
            placeholder="product Id / product name"
            onChange={handleSearchString}
            value={searchString}
            id='product-search'
            name='productSearchTxt'
            className={`form-control`}
          />
        </div>
        <div className='col-12 col-md-5'>
          <button
            className={`btn ${searchString ? 'btn-primary' : 'btn-outline-secondary'}`} type='button'
            onClick={submitSearch} disabled={ searchString ?  false : true }
          >
            Search
          </button>
          { searchString && 
            <button
              className='btn btn-secondary ms-1' type='button'
              onClick={handleClearSearch}
            >
              Clear Search
            </button>
          }
        </div>
      </form>
    );
    return renderSearchPanel();
}

export default ProductSearch;